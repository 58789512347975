import Layout from 'components/comachicart/Layout';
import Maintenance from 'components/comachicart/Maintenance';
import SEO from 'components/seo';
import React, {useEffect, useState} from 'react';
import { useLocation } from '@reach/router';

import {news_endpoint} from '../../constants';

const ReleacePage = () => {
  const [data, setData] = useState();

  useEffect(() => {
    fetchData();
  }, []);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get('id');

  // この値をクエリかなんかで読む
  const newsId = paramValue;
  const fetchData = async () => {
    try {
      const response = await fetch(news_endpoint, {cache: 'no-store'});
      const jsonData = await response.json();
      setData(jsonData[newsId]);
    } catch (error) {
      console.log('データの取得中にエラーが発生しました:', error);
    }
  };

  return (
    <Layout>
      <SEO title='comachicart' path='comachicart/news' />
      {data != null && <Maintenance data={data} />}
    </Layout>
  );
};

export default ReleacePage;
